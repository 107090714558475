import { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MeasureContextProvider from './contexts/MeasureContext';
import { PageContext } from './contexts/PageContext';
import Home from './pages/Home';
import Measurement from './pages/Measurement';

function App() {
    const { local } = useContext(PageContext);
    return local === null ? (
        ``
    ) : (
        <div className="App">
            <BrowserRouter>
                <MeasureContextProvider>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/meting">
                            <Measurement />
                        </Route>
                    </Switch>
                </MeasureContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
