import React, { useContext } from 'react';
import Button from './Button';
import Input from './Input';
import Option from './Option';
import { PageContext } from '../contexts/PageContext';
export default function DispatchButton({ seatsEmpty, setSeatsEmpty, options, dispatch, seats }) {
    const { local } = useContext(PageContext);
    return (
        <div className="rounded-3xl text-white" style={{ backgroundColor: '#5A8BFF' }}>
            <div class="px-6 py-4">
                <p className="text-center mb-2 text-xl">{local.measure.emptySeats}</p>
                <div className="flex">
                    <div className="flex gap-2">
                        {options.map((o, i) => (
                            <Option
                                value={o}
                                key={i}
                                onClick={() => {
                                    if (seatsEmpty === o) {
                                        setSeatsEmpty(0);
                                    } else {
                                        setSeatsEmpty(o);
                                        document.getElementById('emptySeatsInput').value = '';
                                    }
                                }}
                                active={parseInt(seatsEmpty) === o}
                            />
                        ))}
                    </div>
                    <div className="ml-2">
                        <Input
                            placeholder={local.form.placeholder}
                            color="white"
                            type="number"
                            onChange={(e) => setSeatsEmpty(e.target.value)}
                            id="emptySeatsInput"
                            className="border-white"
                            onEnter={dispatch}
                            min="0"
                        />
                    </div>
                </div>
            </div>
            <Button onClick={dispatch}>
                <div className="relative">
                    <p>🎢 Dispatch</p> <div className=" absolute right-3 top-0">({seats})</div>
                </div>
            </Button>
        </div>
    );
}
