import { motion } from 'framer-motion';
import React from 'react';

export default function Button({ children, onClick, textSize = 'xl', textColor = 'white', bgColor = 'bg-blue' }) {
    return (
        <motion.button
            onClick={onClick}
            whileTap={{ scale: 0.9 }}
            className={`text-${textColor} ${bgColor}-700 p-2 w-full text-${textSize} bold rounded-2xl transition-all`}
        >
            {children}
        </motion.button>
    );
}
