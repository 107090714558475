import React, { useContext } from 'react';
import Button from './Button';
import Modal from './Modal';
import { PageContext } from '../contexts/PageContext';
export default function ModalDispatchSuccess({ persons, closeAction, undoAction }) {
    const { local } = useContext(PageContext);

    return (
        <Modal width={400} title={local.measure.modalDispatchSuccess.title.replace('#', persons)} closeAction={closeAction}>
            <div class="flex justify-end gap-4 mt-4">
                <div>
                    <Button textSize="base" textColor="gray-500" bgColor="" onClick={undoAction}>
                        {local.form.undo}
                    </Button>
                </div>
                <div>
                    <Button textSize="base" onClick={closeAction}>
                        {local.form.continue}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
