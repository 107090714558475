import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { MeasureContext } from '../contexts/MeasureContext';
import Button from './Button';
import Modal from './Modal';
import { PageContext } from '../contexts/PageContext';
export default withRouter(function ModalMeasureSave({ throughput, history, closeAction }) {
    const { local } = useContext(PageContext);

    const { name, startedAt, setName, setStartedAt, setSeats, setRedirect } = useContext(MeasureContext);
    const handleSave = () => {
        let m = JSON.parse(window.localStorage.getItem('ridedetective'));
        if (m === null) m = [];
        console.log(throughput);
        window.localStorage.setItem(
            'ridedetective',
            JSON.stringify([{ name, start: startedAt, end: new Date(), throughput: Math.round(throughput) }, ...m])
        );
        handleDelete();
    };

    const handleDelete = () => {
        setName('');
        setStartedAt(null);
        setSeats(null);
        setRedirect(false);
        history.push('/');
    };
    return (
        <Modal width={500} title={local.measure.modalEnd.title} closeAction={closeAction}>
            <div class="flex justify-end gap-4 mt-4">
                <div>
                    <Button textSize="base" textColor="gray-500" bgColor="" onClick={handleDelete}>
                        {local.form.delete}
                    </Button>
                </div>
                <div>
                    <Button textSize="base" onClick={handleSave}>
                        {local.form.save}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
