import React from 'react';

export default function Option({ value, onClick, active }) {
    return (
        <button
            className={`border-2 border-white py-1 px-2 rounded-lg bold text-sm ${active ? `text-blue-500 bg-white` : `text-white`}`}
            onClick={onClick}
        >
            {value}
        </button>
    );
}
