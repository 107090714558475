import React from 'react';

export default function Input({
    onEnter,
    onChange,
    value,
    id,
    color = 'gray',
    colorShade = 500,
    type = 'text',
    className,
    placeholder,
    min,
    ref,
    autoFocus = false,
}) {
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (onEnter !== undefined) {
                onEnter();
                e.target.blur();
            }
        }
    };
    return (
        <div>
            <input
                onChange={onChange}
                value={value}
                onKeyDown={onKeyDown}
                ref={ref}
                min={min}
                autoFocus={autoFocus}
                id={id}
                type={type}
                placeholder={placeholder}
                className={`${
                    color === 'white' ? `placeholder-white` : `placeholder-${color}`
                } outline-none bg-transparent text-${color}-${colorShade} bold ${className} border-2 w-full py-1 px-2 rounded-lg`}
            />
        </div>
    );
}
