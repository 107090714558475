import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { FaPauseCircle, FaPlayCircle, FaRedo, FaTimesCircle, FaUsers } from 'react-icons/fa';
import Statistic from './Statistic';
import { PageContext } from '../contexts/PageContext';

export default function MeasurementsStats({ throughPut, timer, interval, occupation, handlePause, handleEnd, isPaused, dispatches }) {
    const { local } = useContext(PageContext);

    return (
        <div className="flex flex-col gap-4 text-white">
            <div className="flex justify-between gap-4">
                <div className="bg-blue-700 py-1 px-4 text-sm rounded-xl">{timer}</div>
                {isPaused ? (
                    <motion.button whileTap={{ scale: 0.75 }} className="text-sm" onClick={handlePause}>
                        <p>
                            <FaPauseCircle className="inline" /> {local.measure.pause}
                        </p>
                    </motion.button>
                ) : (
                    <motion.button whileTap={{ scale: 0.75 }} className="text-sm" onClick={handlePause}>
                        <FaPlayCircle className="inline" /> {local.measure.resume}
                    </motion.button>
                )}
                <motion.button whileTap={{ scale: 0.75 }} className="text-sm" onClick={handleEnd}>
                    <FaTimesCircle className="inline" /> {local.measure.end}
                </motion.button>
            </div>
            <div className="rounded-xl bg-blue-700 py-2 text-2xl text-center">
                {dispatches.length < 2 ? (
                    <p className="text-base">{local.measure.capacityPlaceholder}</p>
                ) : (
                    <span>
                        {throughPut} {local.capacityUnitLong}
                    </span>
                )}
            </div>
            <div className="grid grid-cols-2">
                <Statistic icon={<FaUsers />} keyName={local.measure.personsKey} value={occupation} />
                <Statistic icon={<FaRedo />} keyName={local.measure.secondsKey} value={interval} />
            </div>
        </div>
    );
}
