export const dutch = {
    page: {
        slogan: 'Capaciteit van achtbanen berekenen',
    },
    deleteHistory: 'Geschiedenis verwijderen',
    start: 'Meting starten',
    capacityUnitShort: 'p/u',
    capacityUnitLong: 'personen/uur',
    modalRideData: {
        title: 'Attractie gegevens',
        form: {
            attraction: 'Attractienaam',
            attractionP: 'Vul een naam in ...',
            seats: 'Aantal zitplaatsen (per trein)',
            seatsP: 'Vul een getal in',
        },
    },
    modalDeleteHistory: {
        title: 'Weet je zeker dat je de meetgeschiedenis wilt verwijderen?',
    },
    measure: {
        capacityPlaceholder: 'Capaciteit na 2 dispatches zichtbaar',
        persons: 'personen',
        person: 'persoon',
        seconds: 'seconden',
        second: 'seconde',
        personsKey: 'gemiddeld per trein',
        secondsKey: 'gemiddelde tussenpoos',
        history: 'Geschiedenis',
        emptySeats: 'Lege stoelen',
        pause: 'Pauzeren',
        resume: 'Hervatten',
        end: 'Beëindigen',
        modalEnd: {
            title: 'Wil je deze meting opslaan?',
        },
        modalDispatchSuccess: {
            title: 'Je hebt een trein met # personen gedispatched!',
        },
    },
    form: {
        cancel: 'Annuleren',
        undo: 'Ongedaan maken',
        placeholder: 'Vul in ...',
        continue: 'Ga verder',
        delete: 'Verwijderen',
        save: 'Opslaan',
        errors: {
            numberNA: 'Vul een getal in',
            numberNegative: 'Vul een getal hoger dan 0 in',
            nameNA: 'Vul een naam in',
        },
    },
};

export const english = {
    page: {
        slogan: 'Calculate rollercoaster capacity',
    },
    deleteHistory: 'Delete history',
    start: 'Start measurement',
    capacityUnitShort: 'p/hr',
    capacityUnitLong: 'persons/hour',
    modalRideData: {
        title: 'Attraction details',
        form: {
            attraction: 'Attraction name',
            attractionP: 'Fill in a name ...',
            seats: 'Amount of seats (per train)',
            seatsP: 'Fill in a number ...',
        },
    },
    modalDeleteHistory: {
        title: 'Are you sure you want to delete the measurement history?',
    },
    measure: {
        capacityPlaceholder: 'Capacity visible after 2 dispatches',
        persons: 'persons',
        person: 'person',
        seconds: 'seconds',
        second: 'second',
        personsKey: 'average per train',
        secondsKey: 'average interval',
        history: 'History',
        emptySeats: 'Empty seats',
        pause: 'Pause',
        resume: 'Resume',
        end: 'End measurement',
        modalEnd: {
            title: 'Do you want to save this measurement?',
        },
        modalDispatchSuccess: {
            title: 'You have dispatched a train with # persons!',
        },
    },
    form: {
        cancel: 'Cancel',
        undo: 'Undo',
        placeholder: 'Fill in ...',
        continue: 'Continue',
        delete: 'Delete',
        save: 'Save',
        errors: {
            numberNA: 'Enter a number',
            numberNegative: 'Please enter a number greater than 0',
            nameNA: 'Enter a name',
        },
    },
};
