import React from 'react';

export default function Statistic({ icon, keyName, value }) {
    return (
        <div className="flex gap-4 mt-2 text-white align-middle">
            <div className="bg-blue-700 p-3 rounded-xl text-xl">{icon}</div>
            <div>
                <p>{value}</p>
                <p className="text-xs light" style={{ marginTop: '-4px', lineHeight: '0.8rem' }}>
                    {keyName}
                </p>
            </div>
        </div>
    );
}
