import React, { useContext, useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Page from '../components/Page';
import useTimer from '../hooks/useTimer';
import { formatTime } from '../utils';
import ModalMeasureSave from '../components/ModalMeasureSave';
import { MeasureContext } from '../contexts/MeasureContext';
import { motion, useAnimation } from 'framer-motion';
import { stagger } from '../animations';
import { withRouter } from 'react-router-dom';
import ModalDispatchSuccess from '../components/ModalDispatchSuccess';
import MeasurementsStats from '../components/MeasurementsStats';
import DispatchButton from '../components/DispatchButton';
import DispatchHistory from '../components/DispatchHistory';
import { PageContext } from '../contexts/PageContext';

export default withRouter(function Measurement({ history }) {
    const { local } = useContext(PageContext);
    const controls = useAnimation(),
        arrow = useAnimation();
    const { timer, isPaused, handleStart, handlePause, handleResume } = useTimer(0);
    const { seats, redirect } = useContext(MeasureContext);
    const [dispatches, setDispatches] = useState([]);
    const [seatsEmpty, setSeatsEmpty] = useState(0);
    const [throughPut, setThroughPut] = useState(0);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [personsTotal, setPersonsTotal] = useState(0);
    const [secondsTotal, setSecondsTotal] = useState(0);
    const [modalSave, setModalSave] = useState(false);
    const options = [1, 2, 3, 4, 5, 6];
    const [loading, setLoading] = useState(true);
    const [dispatchesHistory, setDispatchesHistory] = useState(null);
    const [modalDispatchSuccess, setModalDispatchSuccess] = useState(false);
    useEffect(() => {
        if (!isPaused) {
            handleStart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = () => {
        document.getElementById('emptySeatsInput').value = '';
        setDispatchesHistory(dispatches);
        setDispatches([
            ...dispatches,
            {
                persons: seats - seatsEmpty < 0 ? 0 : seats - seatsEmpty,
                timer,
                createdAt: new Date(),
            },
        ]);
        console.log();
        setPersonsTotal(seats - seatsEmpty < 0 ? personsTotal : personsTotal + seats - seatsEmpty);
        controls.start({
            scale: [1, 1.1, 1],
            transition: {
                duration: 0.3,
            },
        });
        setSeatsEmpty(0);
        setModalDispatchSuccess(true);
    };

    useEffect(() => {
        if (dispatches.length > 1) {
            setSecondsTotal(secondsTotal + (dispatches[dispatches.length - 1].timer - dispatches[dispatches.length - 2].timer));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatches]);

    useEffect(() => {
        if (personsTotal !== 0 && secondsTotal !== 0) {
            let averagePersons = personsTotal / dispatches.length;
            let averageSeconds = secondsTotal / (dispatches.length - 1);
            console.log(averagePersons, averageSeconds);
            setThroughPut((averagePersons / averageSeconds) * 3600);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personsTotal, secondsTotal]);

    useEffect(() => {
        if (redirect) history.push('/');
        setLoading(false);
    }, [history, redirect]);

    return loading ? (
        ``
    ) : (
        <motion.div variants={stagger}>
            <Page>
                {modalDispatchSuccess && (
                    <ModalDispatchSuccess
                        persons={dispatches[dispatches.length - 1].persons}
                        undoAction={() => {
                            setDispatches(dispatchesHistory);
                            setModalDispatchSuccess(false);
                        }}
                        closeAction={() => setModalDispatchSuccess(false)}
                    />
                )}
                {modalSave && <ModalMeasureSave throughput={throughPut} closeAction={() => setModalSave(false)} />}
                <div class="max-w-md mx-auto mt-5 mb-6 px-4 w-full">
                    <MeasurementsStats
                        throughPut={Math.floor(throughPut)}
                        timer={formatTime(timer)}
                        interval={` ${dispatches.length > 1 ? Math.round(secondsTotal / (dispatches.length - 1)) : `0`} ${
                            local.measure.seconds
                        }`}
                        occupation={`${dispatches.length > 0 ? Math.floor(personsTotal / dispatches.length) : `0`} ${
                            local.measure.persons
                        }`}
                        handlePause={!isPaused ? handleResume : handlePause}
                        isPaused={isPaused}
                        handleEnd={() => setModalSave(true)}
                        dispatches={dispatches}
                    />
                </div>
                <div className="bg-white w-full p-4 py-6 " style={{ borderRadius: '30px 30px 0px 0px' }}>
                    <div className="max-w-md mx-auto w-full">
                        <div
                            className="border-b-2 relative mb-5 pb-1 cursor-pointer"
                            onClick={() => {
                                setHistoryVisible(!historyVisible);
                            }}
                        >
                            <h2 className="text-center text-xl text-blue-900">
                                {local.measure.history} ({dispatches.length})
                            </h2>
                            <div class="absolute right-2 top-1 text-2xl text-blue-900">
                                <motion.div whileTap={{ scale: 0.75 }} animate={arrow}>
                                    <FaAngleDown className="transition-all" style={{ transform: historyVisible ? 'rotate(180deg)' : `` }} />
                                </motion.div>
                            </div>
                        </div>
                        {historyVisible && dispatches.length !== 0 && <DispatchHistory dispatches={dispatches} />}
                        <DispatchButton
                            seatsEmpty={seatsEmpty}
                            setSeatsEmpty={setSeatsEmpty}
                            options={options}
                            seats={seats - seatsEmpty < 0 ? 0 : seats - seatsEmpty}
                            dispatch={dispatch}
                        />
                    </div>
                </div>
            </Page>
        </motion.div>
    );
});
