import React, { useContext } from 'react';
import Button from './Button';
import Modal from './Modal';
import { PageContext } from '../contexts/PageContext';
export default function ModalDeleteHistory({ setModalDeleteHistory, setHistory }) {
    const { local } = useContext(PageContext);
    return (
        <Modal title={local.modalDeleteHistory.title} width={400} closeAction={() => setModalDeleteHistory(false)}>
            <div class="grid grid-cols-2 gap-4 mt-4">
                <Button textSize="base" textColor="gray-500" bgColor="" onClick={() => setModalDeleteHistory(false)}>
                    {local.form.cancel}
                </Button>
                <Button
                    bgColor="bg-red"
                    textSize="base"
                    onClick={() => {
                        window.localStorage.removeItem('ridedetective');
                        setModalDeleteHistory(false);
                        setHistory([]);
                    }}
                >
                    {local.form.delete}
                </Button>
            </div>
        </Modal>
    );
}
