import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from '../contexts/PageContext';
import Header from './Header';

const Background = styled.div`
    background-color: #5a8bff;
    min-height: ${(props) => (props.minHeight ? `100vh` : ``)};
`;
export default function Page({ children, slogan, lvh = false, minHeight = false }) {
    const { local } = useContext(PageContext);
    return local === null ? (
        ``
    ) : (
        <Background minHeight={minHeight}>
            <motion.div initial="initial" animate="animate" className="mx-auto pt-4">
                <Header slogan={slogan} />
                <div class="flex flex-col gap-2">{children}</div>
                {
                    //     lvh && (
                    //     <div className="text-center text-xl mt-10">
                    //         <a href="https://www.instagram.com/laurensvanheerde/" className="text-white hover:text-gray-700 transition-all">
                    //             LvH
                    //         </a>
                    //     </div>
                    // )
                }
            </motion.div>
        </Background>
    );
}
