import React, { createContext, useState } from 'react';
export const MeasureContext = createContext();

const MeasureContextProvider = (props) => {
    const [name, setName] = useState('');
    const [seats, setSeats] = useState(null);
    const [startedAt, setStartedAt] = useState();
    const [modalRideData, setModalRideData] = useState(false);
    const [redirect, setRedirect] = useState(true);
    return (
        <MeasureContext.Provider
            value={{
                name,
                setName,
                seats,
                setSeats,
                modalRideData,
                setModalRideData,
                startedAt,
                setStartedAt,
                redirect,
                setRedirect,
            }}
        >
            {props.children}
        </MeasureContext.Provider>
    );
};
export default MeasureContextProvider;
