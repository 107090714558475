import React, { useContext } from 'react';
import { PageContext } from '../contexts/PageContext';

export default function Header({ slogan }) {
    const { language, setLanguage, languages, local } = useContext(PageContext);
    return (
        <div className="text-center mb-4 text-white">
            <div className="relative max-w-md mx-auto">
                <h1 className=" text-2xl bold text-center inline">
                    🕵 <span class="light ">Ride</span>Detective
                </h1>
                <div className="absolute right-4 top-2 flex gap-2">
                    {languages.map((l) => (
                        <button
                            onClick={() => {
                                setLanguage(l);
                                console.log(l);
                            }}
                            className={`${language !== l ? `opacity-60 light` : ``}`}
                        >
                            {l}
                        </button>
                    ))}
                </div>
            </div>
            {slogan && <p className="text-center light">{local.page.slogan}</p>}
        </div>
    );
}
