import React, { useContext, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import Button from '../components/Button';
import ModalRideData from '../components/ModalRideData';
import Page from '../components/Page';
import Row from '../components/Row';
import { MeasureContext } from '../contexts/MeasureContext';
import moment from 'moment';
import ModalDeleteHistory from '../components/ModalDeleteHistory';
import { PageContext } from '../contexts/PageContext';

export default function Home() {
    const { local } = useContext(PageContext);
    const { modalRideData, setModalRideData } = useContext(MeasureContext);
    const [history, setHistory] = useState([]);
    const [modalDeleteHistory, setModalDeleteHistory] = useState(false);
    useEffect(() => {
        if (window.localStorage.getItem('ridedetective') !== null) setHistory(JSON.parse(window.localStorage.getItem('ridedetective')));
    }, []);

    return (
        <Page slogan={true} minHeight={true} lvh={true}>
            {modalRideData && <ModalRideData />}
            {modalDeleteHistory && <ModalDeleteHistory setHistory={setHistory} setModalDeleteHistory={setModalDeleteHistory} />}
            <div className="px-2 max-w-md w-full mx-auto">
                <Button onClick={() => setModalRideData(true)}>🎡 {local.start}</Button>
                <div className="flex flex-col gap-2 mt-5 text-white">
                    {history.map((h) => (
                        <Row>
                            <div>
                                <p>{h.name}</p>
                                <p className="text-sm ">
                                    {moment(h.start).format('DD MMM HH:mm')}-{moment(h.end).format('HH:mm')}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <p className="" title="personen per uur">
                                    {h.throughput} {local.capacityUnitShort}
                                </p>
                            </div>
                        </Row>
                    ))}
                </div>
                {history.length !== 0 && (
                    <div className="flex justify-center mt-5" onClick={() => setModalDeleteHistory(true)}>
                        <button className="text-sm link bold text-white">
                            <FaTrash className="inline" /> {local.deleteHistory}
                        </button>
                    </div>
                )}
            </div>
        </Page>
    );
}
