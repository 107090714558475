import React, { useContext, useState } from 'react';
import { MeasureContext } from '../contexts/MeasureContext';
import Button from './Button';
import Input from './Input';
import Modal from './Modal';
import { withRouter } from 'react-router-dom';
import { PageContext } from '../contexts/PageContext';
export default withRouter(function ModalRideData({ history }) {
    const { local } = useContext(PageContext);
    const { name, setName, seats, setSeats, setModalRideData, setStartedAt, setRedirect } = useContext(MeasureContext);
    const [errors, setErrors] = useState({});
    const handleStart = () => {
        setErrors({});
        let error = {};
        if (name === '') error.name = local.form.errors.nameNA;
        if (seats === null || isNaN(seats)) error.number = local.form.errors.numberNA;
        if (seats < 1 && seats !== null) error.number = local.form.errors.numberNegative;
        if (Object.keys(error).length === 0) {
            setModalRideData(false);
            setStartedAt(new Date());
            setRedirect(false);
            history.push('/meting');
        } else {
            setErrors(error);
        }
    };
    return (
        <Modal title={local.modalRideData.title} width={400} closeAction={() => setModalRideData(false)}>
            <form>
                <div class="flex flex-col gap-4 mt-4">
                    <div>
                        <p className="text-gray-500">{local.modalRideData.form.attraction}</p>
                        <Input
                            className="border-gray-200 focus-within:border-gray-500"
                            placeholder={local.modalRideData.form.attractionP}
                            colorShade={900}
                            onEnter={handleStart}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                    </div>
                    <div>
                        <p className="text-gray-500">{local.modalRideData.form.seats}</p>
                        <div style={{ maxWidth: '200px' }}>
                            <Input
                                placeholder={local.modalRideData.form.seatsP}
                                type="number"
                                className="border-gray-200 focus-within:border-gray-500"
                                colorShade={900}
                                onEnter={handleStart}
                                min={1}
                                onChange={(e) => setSeats(parseInt(e.target.value))}
                            />
                        </div>
                        {errors.number && <p className="text-red-500 text-sm">{errors.number}</p>}
                    </div>
                </div>
            </form>
            <div class="grid grid-cols-2 gap-4 mt-4">
                <Button textSize="base" textColor="gray-500" bgColor="" onClick={() => setModalRideData(false)}>
                    {local.form.cancel}
                </Button>
                <Button
                    textSize="base"
                    onClick={() => {
                        handleStart();
                    }}
                >
                    {local.start}
                </Button>
            </div>
        </Modal>
    );
});
