import { motion } from 'framer-motion';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { fadeInDown } from '../animations';

const Background = styled.div`
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
    background-color: #fefefe;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 20px;
    padding: 30px;
    max-width: ${(props) => props.width}px;
`;

export default function Modal({ width, children, title, closeAction }) {
    return (
        <Background>
            <Content as={motion.div} variants={fadeInDown} width={width} className="mt-8 md:mt-42">
                <div className="flex justify-between relative">
                    <h1 className="md:text-2xl text-xl bold">{title}</h1>
                    <div className="mt-1 link text-gray-800 right-8 top-8 text-xl" onClick={closeAction}>
                        <FaTimes />
                    </div>
                </div>
                {children}
            </Content>
        </Background>
    );
}
