import moment from 'moment';
import React, { useContext } from 'react';
import { PageContext } from '../contexts/PageContext';

export default function DispatchHistory({ dispatches }) {
    const { local } = useContext(PageContext);
    return (
        <div className="mb-4 text-blue-900 max-h-40 pr-4 overflow-y-auto custom_scrollbar">
            {dispatches.map((d) => (
                <div className="border-b-2 flex justify-between">
                    <p>{moment(d.createdAt).format('HH:mm')}</p>
                    <p>
                        {d.persons} {local.measure.persons}
                    </p>
                </div>
            ))}
        </div>
    );
}
