import moment from 'moment';
import React, { createContext, useEffect, useState } from 'react';
import { dutch, english } from '../data/localizations';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
export const PageContext = createContext();

const PageContextProvider = (props) => {
    const [language, setLanguage] = useState(null);
    const [languages] = useState(['EN', 'NL']);
    const [local, setLocal] = useState(null);
    useState(() => {
        setLanguage(localStorage.getItem('language') === null ? 'NL' : localStorage.getItem('language'));
    }, []);
    useEffect(() => {
        if (language !== null) {
            setLocal(language === 'EN' ? english : dutch);
            localStorage.setItem('language', language);
            moment.locale(language === 'EN' ? 'en-gb' : 'nl');
        }
    }, [language]);

    return (
        <PageContext.Provider
            value={{
                local,
                language,
                languages,
                setLanguage,
            }}
        >
            {props.children}
        </PageContext.Provider>
    );
};
export default PageContextProvider;
